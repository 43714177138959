import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// Custom icon for the marker (default marker icon might not work well with Webpack in React)
const markerIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

const MapComponent = () => {
    const position = [17.4943, 78.3995]; // Updated latitude and longitude for the provided address

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <MapContainer center={position} zoom={15} scrollWheelZoom={false} style={{ height: '100%', width: '100%' }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={position} icon={markerIcon}>
                    <Popup>
                        Plot No: 128, Kamalaprasanna Nagar, Allwyn Colony,
                        Opp: Iscon Temple, Kukatpally, Medchel Malkajgiri DIST Hyderabad - 500072
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    );
};

export default MapComponent;
