import React from 'react';
import { useNavigate } from 'react-router-dom'; // Use useNavigate instead of useHistory
import './ServicesPage.css';

const ServicesPage = () => {
    const navigate = useNavigate(); // Replace useHistory with useNavigate

    const handleCTAClick = () => {
        navigate('/contact'); // Use navigate to go to the contact page
    };

    return (
        <div className="services-page">
            {/* Banner Section */}
            <div className="services-banner">
                <div className="banner-overlay1">
                    <h1>Our Services</h1>
                </div>
            </div>

            {/* Our Services Section */}
            <section className="our-services">
            <h2>At AK Gold Buyers, We offer</h2>
            <div className="services-grid">
                    <div className="service-item">
                        <h3>Releasing Pledged Gold</h3>
                        <p>Helping customers release their pledged gold from banks and pawnbrokers at the best possible rates.</p>
                    </div>
                    <div className="service-item">
                        <h3>Buying Gold from Individuals</h3>
                        <p>We buy gold from anyone willing to sell, provided they have a proper purchase receipt.</p>
                    </div>
                </div>
            </section>

        {/* Our Process Section */}
        <section className="our-process-section">
            <h2>Our Process</h2>
            <div className="process-steps-container">
                <div className="process-step-item">
                    <h3><span>1</span> Contact Us</h3>
                    <p>Contact us via phone or form.</p>
                </div>
                <div className="process-step-item">
                    <h3><span>2</span> Get a Quote</h3>
                    <p>Share details and get a quote.</p>
                </div>
                <div className="process-step-item">
                    <h3><span>3</span> Visit Us</h3>
                    <p>Visit our location for gold evaluation.</p>
                </div>
                <div className="process-step-item">
                    <h3><span>4</span> Complete the Deal</h3>
                    <p>Complete the transaction with immediate payment.</p>
                </div>
            </div>
        </section>

            {/* Call to Action Section */}
            <section className="cta-section">
                <h2>Ready to release your pledged gold or sell your gold for the best price?</h2>
                <button className="cta-button" onClick={handleCTAClick}>
                    Contact us today!
                </button>
            </section>
        </div>
    );
};

export default ServicesPage;
