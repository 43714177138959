// File: src/components/Footer.jsx

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="footer-content">
                <div className="footer-column company-info">
                    <h3 className="footer-title">AK Gold Buyers</h3>
                    <p>Turning your gold into cash with trust and transparency.</p>
                    <p>&copy; 2024 AK Gold Buyers. All Rights Reserved.</p>
                </div>

                <div className="footer-column">
                    <h3 className="footer-title">Quick Links</h3>
                    <ul className="footer-links">
                        <li><a href="/about" target="_blank" rel="noopener noreferrer">About Us</a></li>
                        <li><a href="/services" target="_blank" rel="noopener noreferrer">Services</a></li>
                        <li><a href="/contact" target="_blank" rel="noopener noreferrer">Contact Us</a></li>
                        <li><a href="/faq" target="_blank" rel="noopener noreferrer">FAQ</a></li>
                        <li><a href="/blog" target="_blank" rel="noopener noreferrer">Blog</a></li>
                    </ul>
                </div>

                <div className="footer-column">
                    <h3 className="footer-title">Contact Us</h3>
                    <p><FontAwesomeIcon icon={faEnvelope} /> goldbuyerak@gmail.com</p>
                    <p><FontAwesomeIcon icon={faPhone} /> +91 9581631666</p>
                    <p>
                        <FontAwesomeIcon icon={faMapMarkerAlt} /> 
                        Plot No: 128, Kamalaprasanna Nagar, Allwyn Colony,<br />
                        Opp: Iscon Temple, Kukatpally,<br />
                        Medchel Malkajgiri DIST Hyderabad - 500072
                    </p>
                </div>

                <div className="footer-column social-media">
                    <h3 className="footer-title">Follow Us</h3>
                    <div className="social-icons">
                        <a
                            href="https://m.facebook.com/profile.php?id=61565909883694&name=xhp_nt__fb__action__open_user"
                            className="social-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a
                            href="https://www.instagram.com/ak_gold_buyers/"
                            className="social-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
