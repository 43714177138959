import React, { useState } from 'react';
import './FAQ.css'; // Corresponding CSS file for FAQ section

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index); // Toggle active question
    };

    const faqData = [
        {
            question: "What is the process for selling gold?",
            answer: "The process is simple. Bring your gold for valuation, receive an offer, and get instant cash upon agreement."
        },
        {
            question: "How is the gold valued?",
            answer: "We use market-based appraisals to ensure you receive the best possible valuation for your gold."
        },
        {
            question: "Is the process secure and transparent?",
            answer: "Yes, we ensure a secure, transparent process with real-time market rates and no hidden fees."
        },
        {
            question: "What documents are needed for selling gold?",
            answer: "You'll need a valid government-issued ID such as a passport, driver's license, or Aadhaar card."
        }
    ];

    return (
        <section className="faq-section">
            <div className="faq-title-container">
                <h2 className="faq-title">FAQ</h2>
            </div>
            <div className="faq-content">
                <div className="faq-container">
                    {faqData.map((item, index) => (
                        <div key={index} className="faq-item">
                            <div className="faq-question" onClick={() => toggleFAQ(index)}>
                                <span>{item.question}</span>
                                <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
                            </div>
                            {activeIndex === index && (
                                <div className="faq-answer">
                                    <p>{item.answer}</p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default FAQ;
