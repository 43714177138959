import React from 'react';
import './HowItWorks.css';

const HowItWorks = () => {
    return (
        <div className="how-it-works-section">
            <h2 className="how-it-works-title">HOW IT WORKS</h2>
            <div className="how-it-works-content">
                <div className="how-it-works-text">
                    <p>
                        Discover how our seamless and transparent gold-selling process works. From visiting our trusted
                        stores to receiving instant payment, we ensure a quick and reliable transaction at every step.
                    </p>
                    <p>
                        Follow these simple steps to turn your gold into cash instantly, with the highest market value guaranteed.
                    </p>
                </div>

                <div className="steps-container">
                    {/* Central Image */}
                    <div className="central-image">
                        <img src="/images/center-image.png" alt="Process Image" />
                    </div>

                    <div className="step-item top-left">
                        <img src="/images/step-01.png" alt="Step 1" />
                        <h3>Visit Us</h3>
                    </div>
                    <div className="step-item top-right">
                        <img src="/images/step-02.png" alt="Step 2" />
                        <h3>Gold Cleaning</h3>
                    </div>
                    <div className="step-item bottom-left">
                        <img src="/images/step-03.png" alt="Step 3" />
                        <h3>Gold Valuation</h3>
                    </div>
                    <div className="step-item bottom-right">
                        <img src="/images/step-04.png" alt="Step 4" />
                        <h3>Gold Rate</h3>
                    </div>
                    <div className="step-item bottom-center">
                        <img src="/images/step-05.png" alt="Step 5" />
                        <h3>Instant Payment</h3>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
